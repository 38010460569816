<template lang="pug">
  div
    highcharts(v-show='typeCharts === "spline"', style='margin: 10px 0', :options='options', ref='chart')
    highcharts(v-show='typeCharts === "column"', style='margin: 10px 0', :options='columnOptions', ref='column_chart')
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import loading from '@/mixins/loading_mixin';
import {GET_CAMPAIGN_STATE, GET_CAMPAIGN_STAT_HOURS} from '@/store/const/campaign';

let interval;
export default {
  props: {
    typeCharts: {
      type: String,
      default: 'column'
    }
  },
  mixins: [loading],
  data() {
    return {
      isFirstIteration: true,
      lastBid: '',
      lastWin: '',
      lastRequest: '',
      updateArgs: [true, true, {duration: 2000}],
      bidSeries: [],
      winSeries: [],
      requestSeries: [],
      bidCampaign: [],
      winCampaign: [],
      requestCampaign: [],
      allHour: []
    };
  },
  watch: {
    typeCharts(value) {
      if (value) {
        interval = setTimeout(() => {
          this.addPoint();
        }, 3000);
        return;
      }
      clearTimeout(interval)
    },
    getCampaignHours(value) {
      if (value) {
        this.setColumnsStatsData();
      }
    }
  },
  computed: {
    ...mapGetters('Campaign', ['getCampaignState', 'getCampaignHours']),
    options() {
      return {
        chart: {
          type: 'spline',
        },
        xAxis: {
          type: 'datetime',
          crosshair: true,
        },
        title: {text: ''},
        yAxis: {
          title: {
            text: null,
          },
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: false,
            },
          },
        },
        series: [
          {data: this.bidSeries, name: 'Ставки', color: '#FFB5AB'},
          {data: this.winSeries, name: 'Показы', color: '#4A55B0'},
          {data: this.requestSeries, name: 'Запросы', color: '#A0A0A0'},
        ],
      };
    },
    columnOptions() {
      return {
        chart: {
          type: 'column',
        },
        xAxis: {
          type: 'datetime',
          crosshair: true,
          categories: this.allHour,
        },
        title: {text: ''},
        yAxis: {
          title: {
            text: null,
          },
        },
        plotOptions: {
          column: {
            pointWidth: 22,
          }
        },
        series: [
          {data: this.bidCampaign, name: 'Ставки', color: '#FFB5AB'},
          {data: this.winCampaign, name: 'Показы', color: '#4A55B0'},
          {data: this.requestCampaign, name: 'Запросы', color: '#A0A0A0'},
        ],
      };
    },
  },
  async mounted() {
    this.setLoadingActions()
    this.bidSeries = this.GenerateZeroData();
    this.winSeries = this.GenerateZeroData();
    this.requestSeries = this.GenerateZeroData();
    await this.setColumnsStats();
    if (this.typeCharts === 'spline') {
      interval = setTimeout(() => {
        this.addPoint();
      }, 3000);
    }
  },
  beforeDestroy() {
    clearTimeout(interval);
  },
  methods: {
    ...mapActions('Campaign', [GET_CAMPAIGN_STATE, GET_CAMPAIGN_STAT_HOURS]),
    setLoadingActions() {
      this.actions = [
        GET_CAMPAIGN_STATE,
        GET_CAMPAIGN_STAT_HOURS
      ];
    },
    async setColumnsStats() {
      await this.GET_CAMPAIGN_STAT_HOURS({
        id: this.$route.params.id,
        from: this.$moment().subtract(15, 'hours').valueOf(),
        to: this.$moment().valueOf(),
      })
      if (this.isError(GET_CAMPAIGN_STAT_HOURS)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Ошибка сервера'
        })
      }
    },
    setColumnsStatsData() {
      this.bidCampaign = [];
      this.winCampaign = [];
      this.requestCampaign = [];

      const data = Object.keys(this.getCampaignHours)
      data.forEach(key => {
        this.bidCampaign.push(this.getCampaignHours[key].bid)
        this.winCampaign.push(this.getCampaignHours[key].win)
        this.requestCampaign.push(this.getCampaignHours[key].request)
      });
      this.allHour = data.map(key => this.$moment(key).format('DD.MM HH:mm')).sort()
    },
    async addPoint() {
      if (this.typeCharts === 'spline') {
        if (this.$route.params.id) {
          await this.GET_CAMPAIGN_STATE({
            id: this.$route.params.id,
          })
          if (this.isError(GET_CAMPAIGN_STATE)) {
            this.$notify({
              type: 'error',
              title: 'Ошибка',
              text: 'Ошибка сервера'
            })
          }
          const x = new Date().getTime() + 3600000 * 3;
          if (this.isFirstIteration) {
            this.isFirstIteration = false;
            this.lastBid = this.getCampaignState.bid;
            this.lastWin = this.getCampaignState.win;
            this.lastRequest = this.getCampaignState.request;
            this.addPoint();
            return;
          }
          this.$refs.chart.chart.series[0].addPoint({x, y: this.getCampaignState.bid - this.lastBid}, false, true);
          this.$refs.chart.chart.series[1].addPoint({x, y: this.getCampaignState.win - this.lastWin}, true, true);
          this.$refs.chart.chart.series[2].addPoint({
            x,
            y: this.getCampaignState.request - this.lastRequest
          }, true, true);
          this.lastBid = this.getCampaignState.bid;
          this.lastWin = this.getCampaignState.win;
          this.lastRequest = this.getCampaignState.request;
          interval = setTimeout(() => {
            this.addPoint();
          }, 3000);
        }
      }
    },
    GenerateZeroData() {
      const time = new Date().getTime() + 3600000 * 3;
      const data = [];
      for (let i = 29; i >= 0; i--) {
        data.push({
          x: time - i * 1000,
          y: 0,
        });
      }
      return data;
    },
  },
};
</script>
