<template lang="pug">
	v-container(fluid)
		v-row(justify='space-between', align='center')
			h2 {{ getCampaign.name }}
			div.mr-4
				tooltip(
					:text='lock ? "Закрыт доступ к спрофилированным данным" : "Открыт доступ к спрофилированным данным"'
				)
					v-btn(fab, outlined, x-small, :color='lock ? "red" : "accent"')
						v-icon {{ lock ? 'lock_outline' : 'lock_open' }}
		v-row.mt-6(v-if='this.getCampaign.calendar', align='center')
			v-icon(small) mdi-calendar
			span.fz-14.ml-2 {{ displayDate }}
		v-row.flex-nowrap(align='center')
			v-switch.ml-0.pl-0(
				v-model='campaign_status',
				:color='campaignStatusColor(getCampaign.status)',
				@click='campaignStatusClick(getCampaign)',
				readonly
			)
			tooltip(text='Редактировать')
				v-btn.ml-2(
					@click='$router.push("/radar/campaign/" + $route.params.id + "/edit")',
					outlined,
					fab,
					x-small,
					color='primary'
				)
					v-icon edit
			popup-copy(:name='getCampaign.name', :id='getCampaign.id')
			tooltip(text='Сменить график')
				v-btn.ml-2(
					@click='chartsName = "column"',
					v-if='chartsName === "spline"',
					outlined,
					fab,
					x-small,
					color='primary'
				)
					v-icon mdi-archive-outline
				v-btn.ml-2(
					@click='chartsName = "spline"',
					v-if='chartsName === "column"',
					outlined,
					fab,
					x-small,
					color='primary'
				)
					v-icon mdi-archive-outline
			v-row.flex-nowrap.ml-4(justify="space-between", align='center')
				v-col(v-if='getCampaign.bidStrategy === "fixCPM" && getCampaign.bidType === "rating"')
					.stat__title CPP
					.stat__total {{ getCampaign.cpm ? formatNumber(getCampaign.cpm) : '-' }} &#8381
				v-col
					.stat__title {{ getCampaign.bidType === 'rating' ? 'Рейтинг' : 'Показов' }}
					.stat__total(v-if='emptyKeys') - / -
					.stat__total(v-else) {{ factStats }}
						span.stat__title / {{ getCampaign.bidType === 'rating' ? formatNumber(getCampaign.limit.trp) : formatNumber(getCampaign.limit.totalShow) }}
				v-col(v-if='getCampaign.bidType === "show"')
					.stat__title Дневные показы
					.stat__total(v-if='emptyKeys') - / -
					.stat__total(v-else) {{ getCampaignState.hasOwnProperty('dayWin') ? formatNumber(getCampaignState.dayWin) : formatNumber(getCampaign.state.dayWin) }}
						span.stat__title= " / {{ formatNumber(getCampaign.limit.dayLoad) }}"
				v-col
					.stat__title Бюджет
					.stat__total(v-if='emptyKeys') - / -
					.stat__total(v-else) {{ creativeTotals.hasOwnProperty('budget') ? creativeTotals.budget : formatNumber(getCampaign.state.budget) }}
						span.stat__title= " / {{ formatNumber(getCampaign.limit.totalBudget) }}"
				v-col(v-if='getCampaign.bidType === "show"')
					.stat__title Дневной бюджет
					.stat__total(v-if='emptyKeys') - / -
					.stat__total(v-else) {{ getCampaignState.hasOwnProperty('dayBudget') ? formatNumber(getCampaignState.dayBudget) : formatNumber(getCampaign.state.dayBudget) }}
						span.stat__title= " / {{ formatNumber(getCampaign.limit.dayBudget) }}"
				v-col
					.stat__title {{ getCampaign.bidType === 'rating' ? 'OTS' : 'TRP' }}
					.stat__total(v-if='emptyKeys') -
					.stat__total(v-else) {{ getCampaign.bidType === 'rating' ? creativeTotals.ots || getCampaign.state.ots || 0 : creativeTotals.trp || getCampaign.state.trp || 0 }}
						span.stat__title(v-if='getCampaign.limit.trp && getCampaign.bidType !== "rating"')= " / {{ getCampaign.limit.trp }}"
				v-col
					.stat__title GRP
					.stat__total(v-if='emptyKeys') -
					.stat__total(v-else) {{ creativeTotals.grp || getCampaign.state.grp || 0 }}
				v-col.style-surfaces.text-right.pa-0.pr-2(v-if='Object.keys(getInventoryStatus).length > 0')
					popup-no-active-surfaces(:ids='getInventorySurfaces.surfaceIDs')
					.stat__total {{ noActiveSurfaces }}
				v-col.text-right.d-flex.justify-center(offset-xl="1" offset-lg="1")
					div
						.stat__title Поверхностей
						.stat__total {{ getSelectedSurfaces.length }}
		v-row
			v-col.pa-0.ma-0(cols='11', v-if='chartsName === "spline" || chartsName === "column"')
				chart(:typeCharts="chartsName")
			v-col(cols='1', :class='{"d-flex": !chartsName}')
				v-btn.mt-4.ml-10(
					width='50',
					height='33',
					elevation='0',
					color='#4A55B0'
					outlined,
					:class='{"btn-active": chartsName === "column", "ml-column": !chartsName}'
					@click='chartsName = "column"',
				)
					img(src='@/assets/SVG/icons/no-active-chart_column.svg', v-if='chartsName === "spline" || !chartsName')
					img(src='@/assets/SVG/icons/active-chart_column.svg', v-if='chartsName === "column"')
				.border-chart.mt-1.ml-16(v-if='chartsName === "column"')
				v-btn.mt-2.ml-10(
					v-if='chartsName === "column"',
					width='50',
					height='33',
					elevation='0',
					color='accent',
					:loading='isLoading'
					@click='refreshChart',
					outlined
				)
					img(src='@/assets/SVG/icons/refresh.svg')
				v-btn.mt-4.ml-10(
					width='50',
					height='33',
					elevation='0',
					color='accent',
					:class='{"btn-active": chartsName === "spline", "ml-spline": !chartsName}'
					@click='chartsName = "spline"',
					outlined
				)
					img(src='@/assets/SVG/icons/active-chart.svg', v-if='chartsName === "spline"')
					img(src='@/assets/SVG/icons/no-active-chart.svg', v-if='chartsName === "column" || !chartsName')
				v-col.mt-16(v-if='chartsName')
					div.mt-16
						v-btn.mt-16.ml-9(
							width='54',
							height='33',
							elevation='0',
							color='accent',
							@click='chartsName = ""',
							outlined,
							small
						) Скрыть
		v-row.mb-4(justify='space-between')
			v-col(cols='6')
				.center-block
					v-select(
						:items='status',
						:menu-props='{ bottom: true, offsetY: true }',
						label='Статус',
						v-model='creativeStatus',
						clearable,
						dense,
						outlined,
						hide-details,
						style='max-width: 200px',
						append-icon='mdi-chevron-down'
					)
					v-menu(
						ref='menu',
						v-model='menu',
						:close-on-content-click='false',
						transition='scale-transition',
						offset-y='',
						min-width='auto'
					)
						v-date-picker(
							v-model='date',
							:max='new Date().toISOString().substr(0, 10)',
							min='1950-01-01',
							first-day-of-week='1'
							@change='save',
							range,
							locale='ru-ru'
						)
						template(v-slot:activator='{ on, attrs }')
							v-text-field.ml-2(
								v-model='dateFormatted',
								label='Статистика за период',
								prepend-icon='mdi-calendar',
								readonly='',
								v-bind='attrs',
								v-on='on',
								dense,
								outlined,
								hide-details
							)
					v-btn.ml-4(@click='applyChanges', elevation='0', color='accent') Обновить
					export_excel(:data="filteredCreativesForExport")

			v-col(cols='5')
				.center-block
					v-text-field.ml-2(label='Поиск', v-model='search', dense, outlined, hide-details)
					popup-add-creative(:campaignID='getCampaign.id', :creativeIDs='getCreativeIDs', @refresh='refresh()')
					v-btn.ml-2(@click='$router.push("/radar/creatives/")', elevation='0', color='accent') Настройки креативов
		v-data-table.v-data-table--custome(
			:headers='creativeHeaders',
			:items='filteredCreatives',
			:hide-default-footer='getCreatives.length < 11',
			:search='search',
			no-data-text='Нет креативов',
			no-results-text='Нет креативов удовлетворяющих условие'
		)
			template(slot="body.prepend")
				tr
					th
					th
					th Итог по креативам
					th
					template(v-for="header in creativeHeaders")
						th(v-if="totals.includes(header.value)") {{ creativeTotals[header.value.split('.')[1]] }}
			template(v-slot:item.approved='{ item }')
				div(v-if='item.approved === "approve"') Одобрено
				div(v-if='item.approved === ""') На модерации
				div(v-if='item.approved === "disapprove"', style='position: relative')
					tooltip(text='Ошибка')
						v-icon(
							color='red',
							style='position: absolute; left: -10px; top: -4px; transform: translateX(-100%)'
						)
							| info_outline
					| Ошибка
			template(v-slot:item.preview='{ item }')
				video-preview(:previewURL='item.previewURL', :id='item.id')
			template(v-slot:item.publisher_creative_id='{ item }')
				div(
					v-if='item.publisher_creative_id !== ""'
					style='display: flex; justify-content: space-between'
				)
					.id-icon(@click='copyId(item.publisher_creative_id)')
			template(v-slot:item.name='{ item }')
				div.pr-2
					v-btn.v-btn--table-link.v-btn--table-link--colored(
						outlined,
						small,
						:color='statusInfo(item.approved).color',
						:style='{ borderColor: statusInfo(item.approved).color + " !important" }'
					) {{ item.name }}
			template(v-slot:item.state.budget='{ item }')
				span {{ item.state && item.state.budget ? formatNumber(item.state.budget) : 0 }}
			template(v-slot:item.state.win='{ item }')
				span {{ item.state && item.state.win ? formatNumber(item.state.win) : 0 }}
			template(v-slot:item.state.budget='{ item }')
				span {{ item.state && item.state.budget ? formatNumber(item.state.budget) : 0 }}
			template(v-slot:item.state.bid='{ item }')
				span {{ item.state && item.state.bid ? formatNumber(item.state.bid) : 0 }}
			template(v-slot:item.state.ots='{ item }')
				span {{ item.state && item.state.ots ? formatNumber(item.state.ots) : 0 }}
			template(v-slot:item.state.cpm='{ item }')
				span {{ item.state && item.state.win ? formatNumber(item.state.budget / item.state.win) : 0 }}
			template(v-slot:item.state.cpp='{ item }')
				span {{ item.state && item.state.trp ? formatNumber(item.state.budget / item.state.trp) : 0 }}
			template(v-slot:item.state.grp='{ item }')
				span {{ item.state && item.state.grp ? formatNumber(item.state.grp) : 0 }}
			template(v-slot:item.state.trp='{ item }')
				span {{ item.state && item.state.trp ? formatNumber(item.state.trp) : 0 }}
			template(v-slot:item.actions='{ item }')
				div(style='display: flex; align-items: center')
					tooltip(:text='item.active ? "Отключить" : "Включить"')
						v-switch(
							color='#4A55B0',
							:input-value='item.active',
							:value='item.active',
							@change='setCreativeStatus(item.id, !!$event)'
						)
					tooltip(text='Отвязать')
						v-btn.ml-4(@click='removeCreative(item.id)', fab, outlined, x-small, color='primary')
							v-icon
								| close
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Tooltip from '@/components/tooltip';
import PopupCopy from '@/components/popup/list-campaigns/copy';
import PopupAddCreative from '@/components/popup/campaign/addCreatives';
import loading_mixin from '@/mixins/loading_mixin';
import videoPreview from '@/components/creatives/videoPreview';
import chart from '@/components/campaignSave/chart';
import export_excel from '@/components/export_excel';
import PopupNoActiveSurfaces from '@/components/popup/campaign/noActiveSurfaces';
import {formatRquestStatsData} from '@/mixins/date_mixin';
import {GET_CITIES, GET_PUBLISHERS} from '@/store/const/map';
import {GET_CREATIVE_LIST, SET_CREATIVE_STATUS, GET_CREATIVE_STATUSES} from '@/store/const/creatives';
import {GET_INVENTORY_STATUS} from '@/store/const/inventory';
import {
	GET_CAMPAIGN,
	GET_CAMPAIGN_META,
	SET_CAMPAIGN_CREATIVE,
	SET_CAMPAIGN_STATUS,
	SET_SURFACES,
	GET_CAMPAIGN_STAT_HOURS,
	GET_CAMPAIGN_STATE,
} from '@/store/const/campaign';

export default {
	name: 'Campaign',
	components: {Tooltip, PopupCopy, PopupAddCreative, PopupNoActiveSurfaces, videoPreview, chart, export_excel},
	mixins: [formatRquestStatsData, loading_mixin],
	data() {
		return {
			creativeStatus: '',
			active: true,
      campaign_status: false,
			lock: true,
			search: '',
			type: '',
			date: [],
			menu: false,
			status: [
				{value: true, text: 'Активные'},
				{value: false, text: 'Остановленные'},
			],
			totals: [
				'state.budget',
				'state.win',
				'state.bid',
				'state.ots',
				'state.cpm',
				'state.cpp',
				'state.grp',
				'state.trp',
			],
			headers: [
				{
					value: 'preview',
					width: 50,
					sortable: false,
				},
				{
					value: 'publisher_creative_id',
					width: 50,
					sortable: false,
				},
				{
					text: 'Имя',
					value: 'name',
					width: 250,
				},
				{
					text: 'Статус модерации',
					value: 'approved',
				},
				{
					text: 'Расходы',
					value: 'state.budget',
				},
				{
					text: 'Показы',
					value: 'state.win',
				},
				{
					text: 'Ставки',
					value: 'state.bid',
				},
				{
					text: 'OTS',
					value: 'state.ots',
				},
				{
					text: 'CPM',
					value: 'state.cpm',
				},
				{
					text: 'CPP',
					value: 'state.cpp',
				},
				{
					text: 'GRP',
					value: 'state.grp',
				},
				{
					text: 'TRP',
					value: 'state.trp',
				},
				{
					value: 'actions',
					sortable: false,
					width: 126,
				},
			],
			state: [
				{
					name: 'Ставка',
					value: 186332,
				},
				{
					name: 'Показов',
					value: 186332,
					max: 500000,
				},
				{
					name: 'Дневные показы',
					value: 186332,
					max: 500000,
				},
				{
					name: 'Бюджет',
					value: 186332,
					max: 500000,
				},
				{
					name: 'Дневной бюджет',
					value: 186332,
					max: 500000,
				},
				{
					name: 'TRP',
					value: 186332,
					max: 500000,
				},
				{
					name: 'GRP',
					value: 186332,
				},
				{
					name: 'GRP',
					value: 186332,
				},
			],
			chartsName: 'column'
		};
	},
	computed: {
		...mapGetters('Campaign', [
			'getCampaign',
			'getCampaignState',
			'getSelectedSurfaces',
			'getInventorySurfaces',
		]),
		...mapGetters('Creatives', ['getCreatives', 'getCreativeIDs']),
		...mapGetters('Inventory', ['getInventoryStatus']),
		creativeHeaders() {
			if (this.getCampaign.bidType === 'rating') {
				return this.headers.filter((header) => header.value !== 'state.win' && header.value !== 'state.bid' && header.value !== 'state.cpm');
			}
			return this.headers.filter((header) => header.value !== 'state.cpp');
		},
		factStats() {
			if (this.getCampaign.bidType === 'rating') {
				return this.creativeTotals.trp;
			}
			return this.creativeTotals.win
		},
		displayDate() {
			let total = '';
			let date = new Date(this.getCampaign.calendar.dates[0].from);
			let dd = date.getDate(),
				mm = date.getMonth() + 1,
				yy = date.getFullYear();
			if (dd < 10) dd = '0' + dd;
			if (mm < 10) mm = '0' + mm;
			if (yy < 10) yy = '0' + yy;

			total = `${dd}.${mm}.${yy}`;

			let date2 = new Date(this.getCampaign.calendar.dates[0].to);
			let dd2 = date2.getDate(),
				mm2 = date2.getMonth() + 1,
				yy2 = date2.getFullYear();
			if (dd2 < 10) dd2 = '0' + dd2;
			if (mm2 < 10) mm2 = '0' + mm2;
			if (yy2 < 10) yy2 = '0' + yy2;
			total += `   -   ${dd2}.${mm2}.${yy2}`;
			return total;
		},
		dateFormatted() {
			let total = ''
			if (!this.date.length) {
				return total
			}
			total += this.date[0] < this.date[1]
				? this.$moment(this.date[0]).format('DD.MM.YYYY')
				: this.$moment(this.date[1]).format('DD.MM.YYYY');
			total += ' - '
			total += this.date[0] < this.date[1]
				? this.$moment(this.date[1]).format('DD.MM.YYYY')
				: this.$moment(this.date[0]).format('DD.MM.YYYY');
			return total;
		},
		filteredCreatives() {
			if (typeof this.creativeStatus === 'boolean') {
				return this.getCreatives.filter((i) => i.active === this.creativeStatus);
			}
			return this.getCreatives;
		},
		filteredCreativesForExport() {
			return this.filteredCreatives.map(creative => {
				return {
					name: creative.name,
					approved: creative.approved,
					...creative.state
				}
			})
		},
		creativeTotals() {
			let total = {
				budget: 0,
				win: 0,
				bid: 0,
				ots: 0,
				cpm: 0,
				cpp: 0,
				grp: 0,
				trp: 0,
				dayWin: 0,
				dayBudget: 0
			}
			if (!this.filteredCreatives.length) {
				return total
			}
			for (const creative of this.filteredCreatives) {
				if (!creative.state) {
					continue;
				}

				const { budget, win, bid, ots, dayWin, trp, grp, dayBudget } = creative.state

				total.budget += budget
				total.win += win
				total.bid += bid
				total.ots += ots
				total.dayWin += dayWin
				total.dayBudget += dayBudget
				total.grp += grp
				total.trp += trp
			}

			total.cpm += total.budget / total.win
			total.cpp += total.budget / total.trp

			return Object.fromEntries(
				Object.entries(total).map(([key, value]) => [key, this.formatNumber(value)])
			)
		},
		noActiveSurfaces() {
			const noActiveSurfaces = []
			const status = Object.keys(this.getInventoryStatus)
			status.forEach(key => {
				noActiveSurfaces.push(this.getInventoryStatus[key].noActiveSurfaces)
			});
			return noActiveSurfaces.toString()
		},
		emptyKeys() {
			return !Object.keys(this.creativeTotals).length && !Object.keys(this.getCampaign).length
		}
	},
	async mounted() {
		let data = {
			id: this.$route.params.id,
			status: 'all',
			type: 'all',
		};
		await this.GET_CITIES()
		if (this.isError(GET_CITIES)) {
			this.$notify({
				type: 'error',
				title: 'Ошибка',
				text: this.errorMessage(GET_CITIES) || 'Ошибка в получении списка городов',
			});
		}
		await this.GET_PUBLISHERS()
		if (this.isError(GET_PUBLISHERS)) {
			this.$notify({
				type: 'error',
				title: 'Ошибка',
				text: this.errorMessage(GET_PUBLISHERS) || 'Ошибка в получении списка паблишеров',
			});
		}
		await this.GET_CREATIVE_LIST({
			campaignIDs: [this.$route.params.id],
		})
		if (this.isError(GET_CREATIVE_LIST)) {
			this.$notify({
				type: 'error',
				title: 'Ошибка',
				text: this.errorMessage(GET_CREATIVE_LIST) || 'Ошибка в получении списка креативов',
			});
		}
		await this.GET_CAMPAIGN(data)
    this.campaignStatusDisplay(this.getCampaign.status)
		if (this.isError(GET_CAMPAIGN)) {
			this.$notify({
				type: 'error',
				title: 'Ошибка',
				text: this.errorMessage(GET_CAMPAIGN) || 'Ошибка в получении кампании',
			});
		}
		await this.GET_CAMPAIGN_STATE({id: this.$route.params.id})
		if (this.isError(GET_CAMPAIGN_STATE)) {
			this.$notify({
				type: 'error',
				title: 'Ошибка',
				text: this.errorMessage(GET_CAMPAIGN_STATE) || 'Ошибка в получении данных кампании',
			});
		}
    await this.refresh();
		await this.GET_CAMPAIGN_META({id: this.$route.params.id})
		if (this.isError(GET_CAMPAIGN_META)) {
			this.$notify({
				type: 'error',
				title: 'Ошибка',
				text: this.errorMessage(GET_CAMPAIGN) || 'Ошибка в получении метаданных',
			});
		}
		await this.SET_SURFACES({id: this.$route.params.id})
		if (this.isError(SET_SURFACES)) {
			this.notify({
				type: 'error',
				text: this.errorMessage(SET_SURFACES)
			})
		}
		await this.GET_INVENTORY_STATUS({campaignIDs: [this.$route.params.id]})
		if (this.isError(GET_INVENTORY_STATUS)) {
			this.notify({
				type: 'error',
				text: this.errorMessage(GET_INVENTORY_STATUS) || 'Ошибка статуса креатива'
			})
		}
		this.setLoadingActions()
	},
	methods: {
		...mapActions('Map', [GET_CITIES, GET_PUBLISHERS]),
		...mapActions('Inventory', [GET_INVENTORY_STATUS]),
		...mapActions('Creatives', [GET_CREATIVE_LIST, SET_CREATIVE_STATUS, GET_CREATIVE_STATUSES]),
		...mapActions('Campaign', [
			GET_CAMPAIGN,
			GET_CAMPAIGN_STAT_HOURS,
			SET_CAMPAIGN_STATUS,
			SET_CAMPAIGN_CREATIVE,
			GET_CAMPAIGN_META,
			SET_SURFACES,
			GET_CAMPAIGN_STATE,
		]),
		setLoadingActions() {
			this.actions = [
				GET_CITIES,
				GET_PUBLISHERS,
				GET_CREATIVE_LIST,
				SET_CREATIVE_STATUS,
				GET_CREATIVE_STATUSES,
				GET_CAMPAIGN,
				GET_CAMPAIGN_STAT_HOURS,
				SET_CAMPAIGN_STATUS,
				SET_CAMPAIGN_CREATIVE,
				GET_CAMPAIGN_META,
				SET_SURFACES,
				GET_CAMPAIGN_STATE,
      ]
		},
		async refreshChart() {
			await this.GET_CAMPAIGN_STAT_HOURS({
				id: this.$route.params.id,
				from: this.$moment().subtract(15, 'hours').valueOf(),
				to: this.$moment().valueOf(),
			})
			if (this.isError(GET_CAMPAIGN_STAT_HOURS)) {
				this.$notify({
					type: 'error',
					title: 'Ошибка',
					text: this.errorMessage(GET_CAMPAIGN_STAT_HOURS) || 'Не удалось обновить данные',
				});
			}
		},
		async applyChanges() {
			let data = {
				campaignIDs: [this.$route.params.id],
				datestart: this.$moment(this.date[0]).startOf('day').valueOf(),
				datestop: this.$moment(this.date[1]).endOf('day').valueOf()
			};
			await this.GET_CREATIVE_LIST(data)
			if (this.isError(GET_CREATIVE_LIST)) {
				this.$notify({
					type: 'error',
					title: 'Ошибка',
					text: this.errorMessage(GET_CREATIVE_LIST) || 'Ошибка в получении списка креативов',
				});
			}
			await this.refreshStatuses();
		},
		async setCreativeStatus(id, active) {
			await this.SET_CREATIVE_STATUS({
				creativeID: id,
				active,
				campaignID: this.getCampaign.id,
			})
			if (this.isError(SET_CREATIVE_STATUS)) {
				this.$notify({
					type: 'error',
					title: 'Ошибка',
					text: this.errorMessage(SET_CREATIVE_STATUS) || 'Ошибка в обновлении статуса',
				});
				return;
			}
			this.$notify({
				type: 'success',
				title: 'Успех',
				text: 'Статус успешно изменен',
			});
			await this.refreshStatuses();
		},
		statusInfo(val) {
			switch (val) {
				case 'disapprove':
					return {
						text: 'Ошибка',
						color: '#FFB5AB',
					};
				case '':
					return {
						text: 'На модерации',
						color: '#DCDFE7',
					};
				case 'approve':
					return {
						text: 'Одобрено',
						color: '#4A55B0',
					};
			}
		},
		async campaignStatusClick(item) {
			switch (item.status) {
				case 'active':
					item.status = 'stopped';
					break;
				case 'stopped':
					item.status = 'paused';
					break;
				case 'paused':
					item.status = 'stopped';
					break;
			}
			await this.SET_CAMPAIGN_STATUS({
				calendar_enabled: false,
				id: item.id,
				status: item.status,
			})
			if (this.isError(SET_CAMPAIGN_STATUS)) {
				this.$notify({
					type: 'error',
					title: 'Ошибка',
					text: this.errorMessage(SET_CAMPAIGN_STATUS) || 'Ошибка в обновлении статуса',
				});
				return
			}
			this.$notify({
				type: 'success',
				title: 'Успешно',
				text: 'Статус кампании успешно изменён',
			});
		},
		campaignStatusColor(val) {
			switch (val) {
				case 'active':
					return '#4A55B0';
				case 'stopped':
					return '#4A55B0';
				case 'paused':
					return '#ffa72d';
			}
		},
		campaignStatusDisplay(val) {
			switch (val) {
				case 'active':
          this.campaign_status = true;
          break;
				case 'stopped':
          this.campaign_status = false;
          break;
				case 'paused':
          this.campaign_status = false;
          break;
			}
		},
		async removeCreative(id) {
			let data = {
				campaignID: this.getCampaign.id,
				creativeIDs: this.getCreativeIDs.filter((a) => a !== id),
			};
			await this.SET_CAMPAIGN_CREATIVE(data)
			if (this.isError(SET_CAMPAIGN_CREATIVE)) {
				this.$notify({
					type: 'error',
					title: 'Ошибка',
					text: this.errorMessage(SET_CAMPAIGN_CREATIVE),
				});
				return;
			}
			await this.refresh();
		},
		async refreshStatuses() {
			await this.GET_CREATIVE_STATUSES({
				campaignID: this.getCampaign.id,
			})
		},
		getDate(date, type) {
			if (date) {
				let dateObject = new Date(date);
				if (type === 'startOfDay') {
					return dateObject.setHours(0, 0, 0, 0);
				} else if (type === 'endOfDay') {
					return dateObject.setHours(23, 59, 59, 999);
				}
				return dateObject;
			}
			return void 0;
		},
		async refresh() {
			let data = {}
			if (this.date && this.date[0] && this.date[1]) {
				data = {
					campaignIDs: [this.getCampaign.id],
					datestart: this.getDate(this.date[0], 'startOfDay'),
					datestop: this.getDate(this.date[1], 'endOfDay'),
				}
			} else {
				data = {
					campaignIDs: [this.$route.params.id],
				}
			}
			await this.GET_CREATIVE_LIST(data)
			if (this.isError(GET_CREATIVE_LIST)) {
				this.$notify({
					type: 'error',
					title: 'Ошибка',
					text: this.errorMessage(GET_CREATIVE_LIST) || 'Ошибка в получении списка креативов',
				});
			}
			await this.GET_CREATIVE_STATUSES({
				campaignID: this.getCampaign.id,
			})
		},
		save(date) {
			this.$refs.menu.save(date);
		},
		copyId(id) {
			this.$copyText(id)
			this.$notify({
				type: 'success',
				title: 'Успешно',
				text: 'Успешно скопировано',
			})
		},
	},
};
</script>

<style lang="scss" scoped>
.stat {
	&__title {
		font-size: 10px;
		font-weight: bold;
		color: #717171;
	}

	&__total {
		font-size: 16px;
		font-weight: bold;
		color: #000;
	}
}

.id-icon {
	width: 25px;
	height: 25px;
	background: url('~@/assets/SVG/icons/id-icon.svg') 50% no-repeat;
	cursor: pointer;
}

.border-chart {
	display: block;
	width: 14px;
	height: 0;
	border: 1px dashed #4A55B0;
	transform: rotate(90deg);
}

.btn-active {
	background: #4A55B0;
}

.ml-spline {
	margin-left: 10px !important;
}

.ml-column {
	margin-left: 0 !important;
}

.style-surfaces {
	border: 1px solid #F44336;
	border-radius: 4px;
}
</style>
