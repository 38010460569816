var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"1240"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"elevation":"0","color":"accent"}},'v-btn',attrs,false),on),[_vm._v("Добавить креативы")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_c('div',[_vm._v("Выберите креативы:")])]),_c('v-card-text',{staticClass:"mt-5"},[_vm._l((_vm.getTableData),function(creatives,index){return (_vm.getTableData.length)?_c('v-row',{key:index,staticClass:"mt-5"},[_c('v-data-table',{staticClass:"mt-5 v-data-table--custome mt-5",attrs:{"headers":_vm.headers,"items":creatives.data,"items-per-page":creatives.show * 10,"hide-default-footer":creatives.data.length < 11},nativeOn:{"click":function($event){return _vm.test($event, creatives)}},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_c('div',{staticClass:"stat__title"},[_vm._v(_vm._s(header.text))]),_c('div',[_c('span',{staticClass:"stat__total"},[_vm._v(_vm._s(creatives.groupName))]),_c('span',{staticClass:"stat__title",staticStyle:{"margin-left":"15px"}},[_vm._v(_vm._s(creatives.data.length + ' креативов'))])])])])]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('popup-group',{attrs:{"type":"edit","groupName":creatives.groupName,"items":creatives.data}})]}},{key:"item.checkbox",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{key:item.id,staticStyle:{"transform":"translateY(-10px)"},attrs:{"value":item.id,"color":"accent","hide-details":""},model:{value:(_vm.creativeIDsList),callback:function ($$v) {_vm.creativeIDsList=$$v},expression:"creativeIDsList"}})]}},{key:"item.preview",fn:function(ref){
var item = ref.item;
return [_c('video-preview',{attrs:{"previewURL":item.previewURL,"id":item.id}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"v-btn--table-link",attrs:{"outlined":"","small":""}},[_vm._v(_vm._s(item.name))])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.width + "х" + item.height))]}},{key:"item.videoDuration",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.videoDuration + "с"))]}},{key:"item.pp",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.publisherName(item)))]}},{key:"item.approved",fn:function(ref){
var item = ref.item;
return [(item.approved === "approve")?_c('div',[_vm._v("Одобрено")]):_vm._e(),(item.approved === "")?_c('div',[_vm._v("На модерации")]):_vm._e(),(item.approved === "disapprove")?_c('div',{staticStyle:{"position":"relative"}},[_c('tooltip',{attrs:{"text":"Ошибка"}},[_c('v-icon',{staticStyle:{"color":"#f30000","position":"absolute","left":"-10px","top":"-4px","transform":"translateX(-100%)"}},[_vm._v("info")])],1),_vm._v("Ошибка")],1):_vm._e()]}}],null,true)})],1):_vm._e()}),_c('div',{staticClass:"mt-5",staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"elevation":"0","color":"accent"},on:{"click":_vm.save}},[_vm._v("Добавить")])],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }