<template lang="pug">
  div
    v-dialog(v-model='dialog', width='1240')
      template(v-slot:activator='{ on, attrs }')
        v-btn.ml-2(elevation='0', color='accent', v-bind='attrs', v-on='on') Добавить креативы
      v-card
        v-card-title.headline.grey.lighten-2
          div Выберите креативы:
        v-card-text.mt-5
          v-row.mt-5(v-for='(creatives, index) in getTableData' :key="index", v-if='getTableData.length')
            v-data-table.mt-5.v-data-table--custome.mt-5(
              :headers='headers',
              :items='creatives.data',
              @click.native='test($event, creatives)',
              :items-per-page='creatives.show * 10',
              :hide-default-footer='creatives.data.length < 11'
            )
              template(v-slot:header.name='{ header }')
                div(style='display: flex; justify-content: space-between')
                  div
                    .stat__title {{ header.text }}
                    div
                      span.stat__total {{ creatives.groupName }}
                      span.stat__title(style='margin-left: 15px') {{ creatives.data.length + ' креативов' }}
              template(v-slot:header.actions='{ header }')
                popup-group(type='edit', :groupName='creatives.groupName', :items='creatives.data')

              template(v-slot:item.checkbox='{ item }')
                v-checkbox(
                  v-model='creativeIDsList',
                  :value='item.id',
                  :key='item.id',
                  color='accent',
                  hide-details,
                  style='transform: translateY(-10px)'
                )

              template(v-slot:item.preview='{ item }')
                video-preview(:previewURL='item.previewURL', :id='item.id')
              template(v-slot:item.name='{ item }')
                v-btn.v-btn--table-link(outlined, small) {{ item.name }}
              template(v-slot:item.size='{ item }')
                | {{ item.width + "х" + item.height }}
              template(v-slot:item.videoDuration='{ item }')
                | {{ item.videoDuration + "с" }}
              template(v-slot:item.pp='{ item }')
                | {{ publisherName(item) }}
              template(v-slot:item.approved='{ item }')
                div(v-if='item.approved === "approve"') Одобрено
                div(v-if='item.approved === ""') На модерации
                div(v-if='item.approved === "disapprove"', style='position: relative')
                  tooltip(text='Ошибка')
                    v-icon(
                      style='color: #f30000; position: absolute; left: -10px; top: -4px; transform: translateX(-100%)'
                    )
                      | info
                  | Ошибка
          .mt-5(style='text-align: right')
            v-btn(@click='save', elevation='0', color='accent')
              | Добавить
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import eventPathMixin from '@/mixins/eventPath';
import Tooltip from '@/components/tooltip';
import videoPreview from '@/components/creatives/videoPreview';
import loading_mixin from "@/mixins/loading_mixin";
import {GET_CREATIVE_LIST} from "@/store/const/creatives";
import {SET_CAMPAIGN_CREATIVE} from "@/store/const/campaign";

export default {
  name: 'AddCreatives',
  components: {Tooltip, videoPreview},
  mixins: [eventPathMixin, loading_mixin],
  computed: {
    ...mapGetters('Creatives', ['getTableData']),
    ...mapGetters('Map', ['getPublishers']),
  },
  props: {
    campaignID: {
      type: String,
      require: true,
    },
    creativeIDs: {
      type: Array,
      require: true,
      default: [],
    },
  },
  watch: {
    creativeIDs(val) {
      if (val) {
        this.creativeIDsList = val;
      }
    }
  },
  data() {
    return {
      creativeIDsList: this.creativeIDs,
      dialog: false,
      headers: [
        {
          value: 'checkbox',
          width: 60,
          sortable: false,
        },
        {
          value: 'preview',
          width: 60,
          sortable: false,
        },
        {
          text: 'Имя',
          value: 'name',
          sortable: false,
          width: 350,
        },
        {
          text: 'Тип',
          value: 'surfaceType',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Размер',
          value: 'size',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Длительность',
          value: 'videoDuration',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Модерация',
          value: 'approved',
          sortable: false,
        },
        {
          text: 'ПП',
          value: 'pp',
          align: 'center',
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.GET_CREATIVE_LIST()
  },
  methods: {
    ...mapActions('Creatives', [GET_CREATIVE_LIST]),
    ...mapActions('Campaign', [SET_CAMPAIGN_CREATIVE]),
    test(event, item) {
      let isButtonClick = false;
      let path = this.eventPath(event);
      path.forEach((a) => {
        if (a.classList) {
          if (a.classList.contains('v-data-table-header')) {
            isButtonClick = true;
            return;
          }
        }
      });
      if (isButtonClick) item.show = !item.show;
    },
    async save() {
      let data = {
        campaignID: this.campaignID,
        creativeIDs: this.creativeIDsList,
      };

      await this.SET_CAMPAIGN_CREATIVE(data)
      if (this.isError(SET_CAMPAIGN_CREATIVE)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: this.errorMessage(SET_CAMPAIGN_CREATIVE),
        });
      } else {
        this.dialog = false;
        this.$emit('refresh');
        this.$notify({
          type: 'success',
          title: 'Успешно',
          text: 'Креативы успешно добавлены',
        });
      }
    },
    publisherName(item) {
      const publisher = this.getPublishers.find((a) => a.id === item.publisherID)
      return publisher ? publisher.name : ''
    },
  },
};
</script>

<style scoped></style>
