<template lang="pug">
  v-dialog(v-model='dialog', width='1240')
    template(v-slot:activator='{ on, attrs }')
      tooltip(text='Просмотреть список неактивных поверхностей')
        .title-popup(v-bind='attrs', v-on='on', @click='requestSurfaces') Неактивные поверхности
    v-card
      v-card-title
        .dialog-title Неактивные поверхности
      v-card-text
        v-row
          v-col(cols='12')
            v-data-table(
              :items='getNoActiveSurfaces'
              :headers='headers'
              :footer-props="{'items-per-page-text':'Записей на страницу', pageText: '{0}-{1} из {2}'}"
              :hide-default-footer='getNoActiveSurfaces.length < 11'
            )
              template(v-slot:item.publisherId='{ item }')
                | {{ publisherName(item) }}
            v-divider
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Tooltip from '@/components/tooltip';
import {GET_UNITS} from '@/store/const/map';

export default {
  name: 'noActiveSurfaces',
  components: {Tooltip},
  props: {
    ids: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      dialog: false,
      headers: [
        {text: 'GID Radar', value: 'id'},
        {text: 'GID поставщика', value: 'gid'},
        {text: 'Поставщик', value: 'publisherId'},
        {text: 'Адрес', value: 'address'}
      ]
    }
  },
  computed: {
    ...mapGetters('Map', ['getUnits', 'getPublishers']),
    getNoActiveSurfaces() {
      return this.getUnits.filter(item => item.isActive === false || item.isDeleted === true)
    }
  },
  methods: {
    ...mapActions('Map', [GET_UNITS]),
    async requestSurfaces() {
      if (this.ids.length > 0) {
        await this.GET_UNITS({ids: this.ids})
      }
    },
    publisherName(item) {
      const publisher = this.getPublishers.find((a) => a.id === item.publisherId)
      return publisher ? publisher.name : item
    }
  }
};
</script>

<style scoped>
.title-popup {
  font-size: 10px;
  font-weight: bold;
  color: #717171;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 2em;
  margin: -2em;
}
</style>